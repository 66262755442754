.product-container{
     padding: 50px;
}
.products{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.product{
    display: flex;  
    align-items: center; 
    background-color: #aaaeb580; 
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
}
.product-image{  
    padding: 0 ;
    margin-right: 30px;
}
.product-image-header{
    padding: 0 10px;
    font-size: 18px;
    font-weight: 600; 
}
.product-image img{
    width: 40px;
    height: 50px;
}
.product.draft{
    background-color: #b55c5080; 
}
.product-header{
    margin-bottom: 20px;
    background-color: #1DC0D0;
    border-radius: 3px;
}
.product-header .product{
    background-color: transparent;   
}
.product-header .product-name-header{
    font-size: 20px;
    min-width: 37%;
    padding: 10px;
    font-weight: 500; 
}

.product-header .product-price-header {
    font-size: 20px;
    font-weight: 700; 
    color: #111;
    min-width: 15%; 
    padding: 10px;
}
.product-status-header{
    font-size: 18px;
    font-weight: 400;
    min-width: 7%;
    padding: 10px;
}

.product-name{ 
    font-size: 18px;
    font-weight: 400;
    min-width: 37%;
    padding: 10px;
    color: blue;
    text-decoration: underline;
}

.product-price{
    font-size: 18px;
    font-weight: 600;
    min-width: 15%;
    color: #111;  
}
.product-status{
    font-size: 18px;
    font-weight: 400;
    min-width: 7%;
    color: #fff;
    background-color: #31a81c; 
    padding:2px 5px;
    border-radius: 50px;
    text-align: center;
}
.draft .product-status{
    color: #fff;
    background-color: #bb1802;
}
.product-setting{
    font-size: 18px;
    font-weight: 400;
    min-width: 10%; 
    padding: 0 10px; 
    width: 100%; 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
}

.edit{
   text-decoration: none;
}
.edit svg{
    font-size: 24px;
    fill: rgb(0, 0, 0);
}
.trash{
    cursor: pointer;
}
.trash svg{
    font-size: 24px;
    fill: rgb(223, 35, 35);
}

.nav-product{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;  
}
.nav-product a{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    padding: 10px;
    border-radius: 3px;
    background-color: #111;
    transition: 0.3s;
}