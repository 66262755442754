.loading-container{
    padding: 50px;
}
.one-product{
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 50px;
}

.one-image img{
    width: 100%;
}
textarea.form-control{
    width: 100%;
    height: 100px;
}
.one-submit{
    background-color: rgb(54, 148, 4);
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 20px;
}