.product-delete{
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 50px;
}
.one-image img{
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}
.product-delete .one-info{
    display:flex;
    flex-direction: column;
    justify-content: center ;
    padding: 20px;
}
.product-delete .one-info-title{
  display: flex;
    flex-direction: column;
    gap: 20px;
}
.product-delete .one-info-title span{
    font-weight: 600;
    font-size: 20px;
}
.product-delete .permalink{
    font-size: 14px;
    color: #000;
    font-weight: 600;
    background-color: #ccc;
    padding: 10px;
    border-radius: 5px;
}
.product-delete .buttons{
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    margin-top: 50px;
}
.buttons button{
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    background-color: rgb(192, 8, 8);
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    outline: none;
}