.login-form{
    width: 340px;
    margin: 150px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
h1{
    margin-bottom: 35px;
    font-size: 28px;
    font-weight: 500;
}
.form-group{
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.form-control{
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    background-color: #fff;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    outline: none;
}
.btn-login
{
    width: 100%;
    height: 40px;
    border-radius: 5px; 
    background-color: #002458;
    color: #fff;
    font-size: 18px;
    font-weight: 400; 
    cursor: pointer;
    border: none;
    transition: all 0.3s ease-in-out;
    outline: none;
}