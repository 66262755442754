
  *,
  ::before,
  ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body { 
    font-family: "Nunito", sans-serif;  
  }
  
  a {
    text-decoration: none;
  }
  button {
    outline: 0 !important;
  }
  
  