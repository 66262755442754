.newproduct-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}
.new-form{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.new-btn{
    width: 100%;
    height: 50px;
    background-color: #00bcd4;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}