.navbar{
    background-color: #002458;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.menu-bars{
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu{
    background: #002458;
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: .85s;
}

.nav-menu.active{
    left: 0;
    transition: .35s;
}

.nav-text{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px    16px;
    list-style: none;
    height: 60px;
    
}
.nav-text a{
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}
.nav-text a:hover{
    background: #f05b07;
}
.nav-menu-items{
    width: 100%;
}
.navbar-toggle{
    background: #002458;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}
span{
    margin-left: 16px;
}